import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Okresy letnie są coraz cieplejsze",
  "slug": "okresy-letnie-coraz-cieplejsze",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/156439239939242",
  "image": "images/okresy-letnie-coraz-cieplejsze.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-08-16",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`☝Czy wiecie że...❓`}</p>
    <p>{`☀️Okresy letnie są coraz cieplejsze – np. w ostatnim 30-leciu średnia liczba dni upalnych w Polsce wzrosła o około 5 dni. Średnia temperatura w miesiącach letnich czerwiec-lipiec-sierpień również zwiększyła się o około 2-3 stopnie C.🥵🔥`}</p>
    <p>{`Co za tym idzie będziemy zużywać więcej prądu (klimatyzacja) ale również wody!  NIE CZEKAJ‼️`}</p>
    <p>{`Zainwestuj w studnie głębinową i zredukuj opłaty za wodę! CZEKAMY NA WAS!☎️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      