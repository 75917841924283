import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Najnowsze informacje i ciekawostki",
  "meta": {
    "description": "Nowości, ciekawostki, gratisy i inne interesujące informacje od firmy AQUA-TEX",
    "title": "Najnowsze informacje i ciekawostki",
    "permalink": "/novelties/"
  },
  "novelties": {
    "mainTitle": "Najnowsze informacje",
    "fbPosts": [{
      "postId": "2F121426923440474",
      "postHeight": "659"
    }, {
      "postId": "2F125689846347515",
      "postHeight": "609"
    }]
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      