import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Studnie głębinowe",
  "meta": {
    "description": "Polskie studnie głębinowe, kompleksowe podłączenia instalacji wodnych, szukanie wody, uzdatnianie wody, czyszczenie studni, odwierty geodezyjne, badanie wody.",
    "title": "Studnie głębinowe",
    "permalink": "/deep-well/"
  },
  "deepWell": {
    "mainTitle": "Studnie głębinowe",
    "mainContent": "<strong>Jesteśmy w mniejszości firm, które dobierają stacje uzdatniania wody wraz z montażem i serwisem</strong>. Decydując się na studnię głębinową, trzeba wiedzieć, że jest to inwestycja przede wszystkim we własną wodę, często jakościowo lepszą od tej oferowanej przez sieć wodociągową. Podczas ich tworzenia, działania naszego zespołu są tak przemyślane, aby cała inwestycja była jak najbardziej opłacalna, przewidywalna oraz świadoma. <br /> Studnie głębinowe, nazywane również wierconymi lub rurowymi, wykonywane są za pomocą tzw. wiertnic. Są to specjalne urządzenia, które działają w oparciu o dwa rodzaje rur – osłonowe i filtracyjne. Rury osłonowe docierają w okolice warstwy wodonośnej, natomiast rury filtracyjne wnikają w nią. Dzięki temu, że pogłębianie studni wierconej jest możliwe na głębokość ponad 10 m, wydobywana z niej woda jest pozbawiona zanieczyszczeń pochodzących np. z nawożenia gruntów lub nieszczelności infrastruktury kanalizacyjnej. Najważniejsze jest to, że cały system daje pewność nieprzerwanego zaopatrzenia w wodę oraz działa niezależnie od wodociągów.",
    "bonusContent": "<strong>Jeżeli nie masz wystarczająco dużo miejsca, aby zmieścić zbiornik przeponowy, proponujemy rozwiązanie, które głownie dedykowane jest dla domków letniskowych - bezobsługowe urządzenie sterujące wraz z małym zbiornikiem przeponowym!</strong>",
    "additionList": [{
      "description": "Poza ofertą standardową oferujemy także:",
      "contentList": [{
        "desc": "zgłoszenie studni jako jedyne zaopatrzenie w wodę"
      }, {
        "desc": "projekt robót geologicznych"
      }, {
        "desc": "montaż nowoczesnych rozwiązań instalacji studni oraz przyłączy"
      }, {
        "desc": "nowoczesne rozwiązania w zakresie nawadniania"
      }],
      "image": "images/deepWell/deepWell-photo-1.jpg"
    }, {
      "description": "Co warto wiedzieć decydując się na studnię głębinową:",
      "contentList": [{
        "desc": "lokalizacja studni jest uzależniona od obecności wody podziemnej"
      }, {
        "desc": "teren, na którym ma powstać studnia, nie powinien znajdować się niżej od poziomu zbiornika na ścieki"
      }],
      "image": "images/deepWell/deepWell-photo-2.jpg"
    }],
    "waterSearchTitle": "Szukanie wody",
    "waterSearchDesc": "Najczęściej zadawane pytanie przez klientów brzmi: “Czy na tym terenie w ogóle znajduje się woda głębinowa?”. Za każdym razem odpowiadamy: “tak, na 100%, natomiast w zależności na jakiej głębokości”, zatem przed rozpoczęciem danego zlecenia, przeprowadzamy analizę map hydrogeologicznych, na podstawie których określamy przybliżoną głębokość planowanej studni. Z roku na rok poziom wód głębinowych obniża się. Z tego względu częściej dokonujemy odwiertów do coraz głębszych warstw wodonośnych.",
    "waterSearchId": "water-search",
    "waterConnectTitle": "Przyłącze wody",
    "waterConnectDesc": "Zajmujemy się również przyłączaniem studni do budynku. Z przyłącza można korzystać w celach budowlanych lub do podlewania ogrodu.  Wycena przyłącza wody zależy od parametrów, które uzyskujemy po odwiercie. W przypadku braku takich informacji, wykonujemy ofertę wstępną, która zawiera cenę przybliżoną. Każda wycena jest indywidualnie dopasowana do preferencji Klienta. Wykonujemy studnie głębinowe zarówno z przyłączem jak i bez, wedle Państwa zapotrzebowania oraz preferencji.",
    "waterConnectId": "water-connect"
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      