import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Kilka ciekawostek",
  "slug": "kilka-ciekawostek",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/120288693554297",
  "image": "images/kilka-ciekawostek.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-06-08",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`☝️Przy pięknej pogodzie nawadnianie jest niezbędne! A dobrej jakości woda to podstawa!`}</p>
    <p>{`Dlatego kilka wodnych ciekawostek dla Was:`}</p>
    <p>{`💦90% światowych zasobów czystej wody znajduje się na Antarktydzie🥶`}</p>
    <p>{`💦Wody podziemne to najistotniejszy element życia w przyrodzie. Ich objętość przekracza kilkakrotnie wszystkie wody oceanu😎`}</p>
    <p>{`💦Acqua di Cristallo Tributo a Modigliani jest najdroższą wodą na świecie. Jedna butelka tego napoju o pojemności 750 mililitrów kosztuje bowiem 60 tys. dolarów😮`}</p>
    <p>{`💦Zdecydowana większość słodkiej wody znajduje się pod ziemią, a nie na jej powierzchni.🧐`}</p>
    <p>{`💦Z całej wody na ziemi ludzie mogą używać tylko około 0,3% tej wody. Tak użyteczna woda występuje w wodach podziemnych, rzekach i jeziorach słodkowodnych.😱`}</p>
    <p>{`W wodę warto zainwestować ale może nie 60 tys. dolarów - u nas trochę taniej 😆`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      