import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Realizujemy również duże deweloperskie zlecenia",
  "slug": "developerskie-zlecenie",
  "url": "https://www.facebook.com/aquatexbeskidy/videos/318918553634690/",
  "image": "images/developerskie-zlecenie.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2022-06-15",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Osiedle Parkowe w Węgierskiej Górce🏗 już rośnie w oczach, a my wiercimy pod studnie głębinową i pompę ciepła!😎`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      