import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Czy na tym terenie w ogóle znajduje się woda głębinowa?",
  "slug": "czy-tu-jest-woda",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/111446877771812",
  "image": "images/czy-tu-jest-woda.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-05-11",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Najczęściej zadawane pytanie przez klientów brzmi: “Czy na tym terenie w ogóle znajduje się woda głębinowa❓”. Za każdym razem odpowiadamy📣: “Tak, natomiast w zależności na jakiej głębokości”📈. Zatem przed rozpoczęciem danego zlecenia, przeprowadzamy analizę map hydrogeologicznych, na podstawie których określamy przybliżoną głębokość planowanej studni🚰. Z roku na rok poziom wód głębinowych obniża się. Z tego względu częściej dokonujemy odwiertów do coraz głębszych warstw wodonośnych.🌊`}</p>
    <p>{`Zapraszamy inwestorów do kontaktu!☎️💪`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      