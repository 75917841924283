import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "HeaderTop",
  "links": [{
    "text": "Zadzwoń: +48601282929",
    "type": "link",
    "icon": "",
    "url": "tel:+48601282929"
  }, {
    "text": "Napisz: biuro@aquatexbeskidy.pl",
    "type": "link",
    "url": "mailto:biuro@aquatexbeskidy.pl",
    "icon": ""
  }, {
    "text": "AQUA-TEX Youtube",
    "type": "link",
    "url": "https://www.youtube.com/channel/UCL3FFwaY5mIyhLgy3uU7YbQ",
    "icon": "assets/icons/youtube.svg"
  }, {
    "text": "AQUA-TEX Facebook",
    "type": "link",
    "url": "https://www.facebook.com/aquatexbeskidy",
    "icon": "assets/icons/facebook.svg"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      