import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Przy pracy z wodą - czystość obowiązkowa!",
  "slug": "przy-pracy-z-woda-czystosc",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/118149500434883",
  "image": "images/przy-pracy-z-woda-czystosc.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-05-29",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Przy pracy z wodą - czystość obowiązkowa!❗️🔥`}</p>
    <p>{`👉Nasza wiertnica posiada unikalny system rurowy, który odprowadza urobek w dogodne miejsce lub bezpośrednio na samochód🚛, co zapobiega zabrudzeniom budynku oraz terenu wokół ale również odróżnia nas od konkurencji.😎`}</p>
    <p>{`👉Nasz zespół składa się z zaprawionych w boju fachowców💪, dzięki którym efekt końcowy pracy zawsze jest zadawalający zarówno dla Państwa jak i dla nas.👍`}</p>
    <p>{`👉Jesteśmy otwarci i elastyczni, dlatego wycena jest dostosowywana indywidualnie do potrzeb klienta👌`}</p>
    <p>{`Zapraszamy do kontaktu ☎601282929☎`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      