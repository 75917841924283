import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Bez WODY nie ma NAS",
  "slug": "bez-wody-nie-ma-nas",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/170535351862964",
  "image": "images/bez-wody-nie-ma-nas.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-09-07",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Bez WODY nie ma NAS🧐 - zebraliśmy klika ciekawostek na temat wody o których pewnie nie wiedzieliście!🤩👇`}</p>
    <p>{`✅Cztery litry benzyny mogą zanieczyścić około 2,8 miliona litrów wody.😳`}</p>
    <p>{`✅W okresie 100 lat cząsteczka wody spędza 98 lat w oceanie, 20 miesięcy jako lód, około 2 tygodnie w jeziorach i rzekach oraz mniej niż tydzień w atmosferze.`}</p>
    <p>{`✅Do wyprodukowania 1 tony stali potrzeba 300 ton wody.`}</p>
    <p>{`✅W atmosferze jest więcej wody niż we wszystkich naszych rzekach razem wziętych.`}</p>
    <p>{`✅Prawie 97% wody na świecie jest słona lub w inny sposób niezdatna do picia. Kolejne 2% jest zamknięte w lodowcach. Pozostaje tylko 1% na wszystkie potrzeby ludzkości – wszystkie potrzeby rolnicze, produkcyjne i społeczne..`}</p>
    <p>{`✅Cała woda, która jest obecnie dostępna na Ziemi dotarła do nas z pomocą komet i planetoid. Stało się to między 4,5 mld lat do 3,8 mld lat podczas okresu zwanego "Wielkim bombardowaniem"`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      