import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Bogata studnia głębinowa",
  "slug": "bogata-studnia-glebinowa",
  "url": "https://www.facebook.com/aquatexbeskidy/videos/362229305435147/",
  "image": "images/bogata-studnia-glebinowa.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-08-21",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Zobaczcie jakie cudo trafiliśmy!🤩 Bogata studnia głębinowa z niesamowicie krystalicznie czysta wodą!💦`}</p>
    <p>{`I jak tu nie inwestować w studnie głębinową?😃`}</p>
    <p>{`Czekamy na Was!☎️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      