import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Strona główna",
  "meta": {
    "description": "Studnie głębinowe Aqua-Tex. Podłączenia instalacji wodnych, szukanie, badanie i uzdatnianie wody, czyszczenie, konserwacja pompy ciepła, odwierty geodezyjne.",
    "title": "Strona główna",
    "permalink": "/"
  },
  "homeInfoModal": {
    "title": "UWAGA‼️ BARDZO WAŻNA INFORMACJA‼️",
    "image": "images/home/home-photo-modal.jpg",
    "mainText": "Zauważyliśmy, że niektóre firmy nadal wiercą studnie głębinowe starą metodą i zostawiają żelazne rury osłonowe wokół rury studziennej. Niestety niewyjęte rury osłonowe po niedługim czasie eksploatacji",
    "subText": "Nasi pracownicy nie mogą przejść obok tego obojętnie.",
    "summaryText": "Na życzenie klienta stosujemy wytrzymałe rury plastikowe! Pamiętajcie o tym przy wyborze odpowiedniej firmy.",
    "warning": "RDZEWIEJĄ I ZANIECZYSZCZAJĄ WODĘ!",
    "success": "AQUATEX BESKIDY zawsze wierci studnie nowoczesną metodą nie zostawiając metalowej rury osłonowej!",
    "info": "Nasi specjaliści wychodząc naprzeciw oczekiwaniom klientów, jako pierwsi wprowadzili nową usługę:",
    "infoContent": "Uzdatniania i Dezynfekcji studni w CENIE ODWIERTU!",
    "buttons": [{
      "title": "Oferta",
      "link": "/offer/",
      "type": "buttonTertiary"
    }, {
      "title": "O nas",
      "link": "/about/",
      "type": "buttonPrimary"
    }]
  },
  "hero": {
    "title": "Studnie Głębinowe <br /> Pompy Ciepła",
    "subtitle": "Oferta na każdą kieszeń od <strong>140zł</strong>!",
    "image": "images/home/home-photo-1.jpg",
    "buttons": [{
      "title": "Nasza oferta",
      "link": "/offer/",
      "type": "buttonSecondary"
    }, {
      "title": "+48 601 282 929",
      "link": "tel:+48601282929",
      "type": "buttonPrimary"
    }, {
      "title": "Sprawdź nas",
      "link": "/about/",
      "type": "buttonSecondary"
    }]
  },
  "homeCountUp": {
    "title": "Opinie naszych klientów",
    "subTitle": "Z dnia na dzień rośnie liczba osób zadowolonych z naszych usług",
    "count": 1013,
    "score": "4,9/5",
    "iconSmile": "assets/icons/smiley-13.svg",
    "iconStars": "assets/icons/stars.svg",
    "iconGoogle": "assets/icons/google_logo.svg",
    "googleOpinions": "https://www.google.com/search?sca_esv=ec0f97d633954ec1&sxsrf=ADLYWIKzCTW7GQFgpdLmS-P2WhqQVRQsxg:1716968741957&uds=ADvngMjcH0KdF7qGWtwTBrP0nt7dMRj0cT-7tAXSZR8GQf4yuwP_5c8_HaUr5MgE3-wyTL5RRwwuwQ71_el19YDpz90IDkceNfzZ2zmMo5qbRv_tPOVtVpqYO8NeLMannAdpLUhLh9yw&si=ACC90nwjPmqJHrCEt6ewASzksVFQDX8zco_7MgBaIawvaF4-7jbcRb9Lt1Y7aocsAEqNAy0oUXChBgbij5Nod8x2dp5EuAjNZT5Unuo3_UKpccnuRLywDKE%3D&q=AQUATEX+Beskidy+-+%C5%9Al%C4%85skie+centrum+wierce%C5%84+hydro-geologicznych+Opinie&sa=X&ved=2ahUKEwimt9H_rrKGAxUBVvEDHWQ_BhMQ3PALegQISBAF&biw=1848&bih=944&dpr=1",
    "opinions": [{
      "user": "Marcin",
      "message": "Super . Szybkie terminy , profesjonalne podejście. Gorąco polecam."
    }, {
      "user": "Beata",
      "message": "Witam. Konkretna firma. Wytłumaczy wszystko, a po zainstalowaniu również pomaga w razie problemów. Polecam."
    }, {
      "user": "Wojciech",
      "message": "Doskonała współpraca, szybka realizacja zlecenia. Polecam"
    }, {
      "user": "Mirosław",
      "message": "Pierwszy etap - poszukiwanie i kopanie studni. Sprawnie i komunikatywnie i najważniejsze woda jest!!!!! Polecam."
    }, {
      "user": "Irek",
      "message": "Firma godna polecenia prace zostały wykonane szybko i sprawnie kontakt z właścicielem na bieżąco pracownicy doświadczeni i pomocni .,Pan od koparki złota raczka polecam szczerze ."
    }, {
      "user": "Paulina",
      "message": "Odwiert oraz podłączenie wykonane na najwyższym poziomie. Polecam!"
    }]
  },
  "features": [{
    "icon": "assets/icons/water_drop.svg",
    "title": "Studnie Głębinowe",
    "link": {
      "title": "czytaj więcej",
      "url": "/deep-well"
    }
  }, {
    "icon": "assets/icons/water_drops.svg",
    "title": "Szukanie wody",
    "link": {
      "title": "czytaj więcej",
      "url": "/deep-well#water-search"
    }
  }, {
    "icon": "assets/icons/connect.svg",
    "title": "Przyłącze Wody",
    "link": {
      "title": "czytaj więcej",
      "url": "/deep-well#water-connect"
    }
  }, {
    "icon": "assets/icons/metrics.svg",
    "title": "Odwierty Pod Pompy Ciepła",
    "link": {
      "title": "czytaj więcej",
      "url": "/borehole"
    }
  }],
  "spot": {
    "videoId": "9vxHZT8QmkE",
    "videoTitle": "Dlaczego powinniście nas wybrać..."
  },
  "masSpot": {
    "videoId": "dxluFVDouHM",
    "videoTitle": "W biurze podczas Świąt..."
  },
  "reviews": [{
    "description": "Podchodzimy z pasją do swojej pracy, dlatego też wiercimy wszystkimi możliwymi technikami i średnicami. Praca naszych specjalistów jest wykonywana z wielkim zaangażowaniem i starannością.",
    "image": "images/home/home-photo-2.jpg"
  }, {
    "description": "Usługi wykonujemy wiertnicami i kompresorami o dużej wydajności powietrza co pozwala nam na szybkie wykonanie studni. Wiertnica posiada unikalny system rurowy, który odprowadza urobek.",
    "image": "images/home/home-photo-6.jpg"
  }, {
    "description": "Nasza ekipa wykonuje zlecone prace z wielką determinacją i starannością - stosujemy najwyższej jakości materiały. Na bieżąco się doszkalamy i aktualizujemy wiedzę o najnowsze rozwiązania.",
    "image": "images/home/home-photo-7.jpg"
  }],
  "advantages": {
    "title": "Dlaczego warto nas wybrać?",
    "advList": [{
      "icon": "assets/icons/tasks.svg",
      "title": "Profesjonalna realizacja",
      "desc": "Najnowsze technologie wiercenia, gwarancja bezpieczeństwa"
    }, {
      "icon": "assets/icons/gift.svg",
      "title": "Najlepsi na rynku",
      "desc": "Wykonujemy odwierty na południu kraju, głównie Beskidy - w każdych warunkach"
    }, {
      "icon": "assets/icons/worker.svg",
      "title": "Nowoczesny sprzęt",
      "desc": "Wykonujemy wiercenia na głębokość sięgającą 150m!"
    }]
  },
  "howWorks": {
    "heading": "Przy wykonywaniu studni kierujemy się przede wszystkim wiedzą na temat budowy geologicznej, warunków hydrogeologicznych i ich właściwej interpretacji w przełożeniu na możliwość uzyskania wody.",
    "image": "images/home/home-photo-5.jpg",
    "buttons": [{
      "title": "Jak działamy",
      "link": "/works",
      "type": "buttonSecondary"
    }]
  },
  "faq": {
    "headline": "Najczęściej zadawane pytania (FAQ)",
    "list": [{
      "title": "Jaka jest cena studni głębinowej?",
      "content": "Studnie głębinową w województwie śląskim i okolicach można  wykonać już od 140 zł za metr. Aby doprowadzić wodę, dobiera się rurę o odpowiedniej średnicy, w zależności od zapotrzebowania. W domach jednorodzinnych zazwyczaj stosuje się rurę 100 – 160 mm.",
      "icon": "assets/icons/toggle_down.svg"
    }, {
      "title": "Jak znaleźć wodę na działce?",
      "content": "Geolog sprawdza występowanie wody przy użyciu map hydrogeologicznych. Na ich podstawie określa również profil geologiczny. W sytuacji, gdy brakuje takich informacji, wymagane jest przeprowadzenie badań (elektro-sejsmicznych lub radiestezyjnych) bezpośrednio na działce klienta.",
      "icon": "assets/icons/toggle_down.svg"
    }, {
      "title": "Gdzie umiejscowić studnię na działce?",
      "content": "Studnię głębinową należy wykonać w miejscu występowania wody na działce. Warstwy wodonośne widoczne są na mapach hydrogeologicznych. Studnię można również zlokalizować w bliskiej odległości od hydroforni, natomiast wymagane jest wtedy zachowanie przepisowych odległości od: granicy działki, osadnika ściekowego, gazu, kabli energetycznych w ziemi i budynków gospodarczych.",
      "icon": "assets/icons/toggle_down.svg"
    }, {
      "title": "Jak wygląda proces odwiertu studni?",
      "content": "Proces wiercenia studni głębinowej odbywa się w kilku etapach. Po pierwsze należy zlokalizować warstwę wodonośną. Jeżeli jest taka możliwość, sprawdza się ją w opraciu o mapy hydrogeologiczne, jeżeli brak informacji w mapach, przeprowadza się badania bezpośrednio na działce Klienta. Kolejnym etapem jest wykonanie odwiertu studni głębinowej przy użyciu wiertnicy do wierceń pionowych, następnie zabudowanie rurami, filtrami oraz zabezpieczeniami. Studnię uzbraja się w pompę, hermetyczną obudowę, rurociąg tłoczny, linki i zawory. Ostatnim etapem jest połączenie studni z budynkiem, a następnie budowa hydroforni wraz ze sterowaniem.",
      "icon": "assets/icons/toggle_down.svg"
    }, {
      "title": "Jak głęboko wiercona jest studnia, na ile metrów?",
      "content": "Głębokość studni zależy przede wszystkim od głębokości na jakiej znajduje się woda. W województwie śląskim głębokość studni głębinowych wynosi 20 – 40 metrów (należy pamiętać, że od 30 metra wymagane jest zezwolenie oraz dodatkowe formalności).",
      "icon": "assets/icons/toggle_down.svg"
    }, {
      "title": "Jak wygląda odwiert pod pompę ciepła?",
      "content": "Odwiert to pierwszy etap podczas instalacji pompy ciepła. Odwiert najlepiej jest zacząć zaraz po wykonaniu fundamentów lub przed rozpoczęciem budowy. Należy jednak pamiętać aby na działce była dostępna bieżąca woda oraz energia elektryczna. Odwiert pod pompę ciepła jest to instalacja gruntowego wymiennika ciepła. Zimą budynek ogrzewany jest przez ciepło wydobywające się z gruntu, natomiast latem odwiert staje się źródłem chłodu. Głębokość odwiertu zazwyczaj wynosi około 100 m.",
      "icon": "assets/icons/toggle_down.svg"
    }, {
      "title": "Czy warto interesować się pompą ciepła?",
      "content": "Z roku na rok coraz więcej osób decyduje się na własną pompę ciepła. Odwierty pod pompy ciepła to najlepsza inwestycja na lata, ze względu na to, iż rury, które zakopane są w ziemi będą służyć nawet kilku następnym pokoleniom. Można powiedzieć, że jest to inwestycja we własną kopalnię węgla. Bardzo wysoka wydajność energetyczna w stosunku do poniesionych nakładów sprawia, że inwestycja zwraca się w przeciągu kilku lat.",
      "icon": "assets/icons/toggle_down.svg"
    }]
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      