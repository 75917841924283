import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Odwiert pod pompę ciepła - urywek z pracy",
  "slug": "odwiert-pod-pompe-ciepla-urywek",
  "url": "https://www.facebook.com/aquatexbeskidy/videos/174904598064790/",
  "image": "images/odwiert-pod-pompe-ciepla-urywek.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-08-23",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`📣Przed nami kolejne wyzwanie‼`}</p>
    <p>{`Dzisiaj wykonujemy odwiert pod POMPE CIEPŁA!🔥  Zobaczcie urywek naszej pracy👇`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      