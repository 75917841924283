import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Jaką pompę dobrać?",
  "slug": "jaka-pompa",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/121977990052034",
  "image": "images/jaka-pompa.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-06-14",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`👉Jedno z częstszych pytań z którymi mierzymy się na co dzień:`}</p>
    <p>{`☝️Panie Radku - super żeście mi tą studnie wywiercili, ale jaką pompę dobrać żeby to hulało?`}</p>
    <p>{`✅Zawsze po odwiercie sprawdzamy wydajność studni pompując wodę ciągiem - sprawdzamy wartości przepływu wody na minutę. Dzięki temu wiemy jaką pompę głębinową dobrać do studni jak również, jaką instalacje przyłączeniową wykonać w domu. 👌`}</p>
    <p>{`Nasi specjaliści doradzą i dobiorą odpowiedni sprzęt dla każdego klienta💪‼`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      