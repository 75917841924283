import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "UWAGA! Szukamy pomocnika do zespołu",
  "slug": "szukamy-pomocnika",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/174659651450534",
  "image": "images/szukamy-pomocnika.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-09-13",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`⚠️UWAGA UWAGA! Szukamy do naszego Aquatexowego zespołu pomocnika operatora wiertnicy! 💦`}</p>
    <p>{`✅Co jest dla nas ważne:`}</p>
    <p>{`Sprawność fizyczna`}</p>
    <p>{`Dyspozycja`}</p>
    <p>{`Chęć do nauki nowych rzeczy`}</p>
    <p>{`Poczucie humoru 😉`}</p>
    <p>{`💪Oferujemy:`}</p>
    <p>{`Praca w świetnym zespole`}</p>
    <p>{`Dużo ciekawej wiedzy i praktycznych umiejętności`}</p>
    <p>{`Wysokie zarobki`}</p>
    <p>{`Wypłaty tygodniowe`}</p>
    <p>{`Czekamy na zgłoszenia pod numerem  ☎️601282929☎️`}</p>
    <p>{`Prośba o udostępnienie!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      