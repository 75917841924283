import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Studnia głębinowa to uniezależnienie się od dostawców wody",
  "slug": "uniezaleznienie-sie-od-dostawcow-wody",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/319030927013405/",
  "image": "images/uniezaleznienie-sie-od-dostawcow-wody.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2022-05-05",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`My doskonale wiemy, że studnia głębinowa to inwestycja, która w domach jednorodzinnych zwraca się bardzo szybko!
😲Obecnie średnia cena wody w woj. śląskim za 1 m3 to prawie 11 zł. Dorosła osoba zużywa prawie 100 litrów wody dziennie. Przy 4 osobowej rodzinie inwestycja w studnie głębinową może zwrócić się nawet w jeden rok❗️
Nie czekaj i skontaktuj się z nami☎️
Doradzimy, wywiercimy i zainstalujemy przyłącze! 🤩🤩`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      