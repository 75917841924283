import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Polityka prywatności",
  "meta": {
    "description": "Polityka prywatności/cookies Aquatex Beskidy",
    "title": "Polityka prywatności",
    "permalink": "/privacy-policy/"
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Polityka prywatności i plików cookies`}</h2>
    <p>{`(dalej: „`}<strong parentName="p">{`Polityka`}</strong>{`”)`}</p>
    <br />
    <h3>{`CZĘŚĆ I `}<br />{` Postanowienia ogólne`}</h3>
    <br />
    <p><strong parentName="p">{`Jakiej strony internetowej dotyczy Polityka?`}</strong></p>
    <p>{`Postanowienia zawarte w Polityce dotyczą strony mieszczącej się pod adresem: `}<a parentName="p" {...{
        "href": "https://aquatexbeskidy.pl/"
      }}>{`https://aquatexbeskidy.pl/`}</a>{` (dalej: „`}<strong parentName="p">{`Strona`}</strong>{` `}<strong parentName="p">{`internetowa`}</strong>{`”).`}</p>
    <p><strong parentName="p">{`Kto jest właścicielem Strony internetowej i administratorem danych osobowych`}</strong>{`?`}</p>
    <p>{`Administratorem Państwa danych osobowych i właścicielem Strony internetowej jest `}<strong parentName="p">{`Radosław Suchanek`}</strong>{` prowadzący działalność gospodarczą pod firmą AQUA-TEX Radosław Suchanek adres: ul. Plażowa 6, 34-350 Węgierska, NIP: 5531967252 Górka (dalej: „`}<strong parentName="p">{`Administrator`}</strong>{`”).`}</p>
    <p><strong parentName="p">{`Jak można skontaktować się z Administratorem? `}</strong></p>
    <p>{`Jeżeli chcą Państwo dowiedzieć się więcej o przetwarzaniu danych osobowych lub skontaktować się z Administratorem, mogą wykorzystać Państwo niżej wskazane dane:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`adres e-mail: `}</strong><a parentName="li" {...{
          "href": "mailto:biuro@aquatexbeskidy.pl"
        }}>{`biuro@aquatexbeskidy.pl`}</a></li>
      <li parentName="ol"><strong parentName="li">{`adres korespondencyjny:`}</strong>{` ul. Plażowa 6, 34-350 Węgierska,`}</li>
      <li parentName="ol"><strong parentName="li">{`telefon:`}</strong>{` `}<a parentName="li" {...{
          "href": "tel:+48601282929"
        }}>{`601282929`}</a></li>
    </ol>
    <p><strong parentName="p">{`Czy administrator zapewnia bezpieczeństwo danych osobowych?`}</strong></p>
    <p>{`Tak, Administrator wdrożył odpowiednie środki techniczne i organizacyjne, które zapewniają bezpieczeństwo przetwarzania danych osobowych.`}</p>
    <p>{`Administrator przywiązuje szczególną wagę do poszanowania prywatności użytkowników Strony internetowej. Pozyskane dane są szczególnie chronione i zabezpieczone przed dostępem osób nieuprawnionych.`}</p>
    <p><strong parentName="p">{`Jakie prawa przysługują osobie, której dane osobowe są przetwarzane przez Administratora?  `}</strong></p>
    <p>{`Przysługuje Państwu prawo do:`}</p>
    <ol>
      <li parentName="ol">{`żądania od Administratora dostępu do Państwa danych osobowych;`}</li>
      <li parentName="ol">{`sprostowania danych osobowych;`}</li>
      <li parentName="ol">{`usunięcia danych osobowych;`}</li>
      <li parentName="ol">{`ograniczenia przetwarzania danych osobowych;`}</li>
      <li parentName="ol">{`wniesienia sprzeciwu wobec przetwarzania;`}</li>
      <li parentName="ol">{`przenoszenia danych;`}</li>
      <li parentName="ol">{`jeżeli przetwarzanie odbywa się na podstawie zgody – prawo do cofnięcia zgody na przetwarzanie danych osobowych;`}</li>
      <li parentName="ol">{`wniesienia skargi do organu nadzorczego, czyli do Prezesa Urzędu Ochrony Danych Osobowych.`}</li>
    </ol>
    <p><strong parentName="p">{`Czy dane będą podlegać profilowaniu?`}</strong></p>
    <p>{`Państwa dane osobowe nie podlegają podejmowaniu decyzji w sposób zautomatyzowany, w tym profilowaniu.`}</p>
    <p>{`Państwa dane również nie będą przekazywane poza EOG.`}</p>
    <br />
    <h3>{`CZĘŚĆ II `}<br />{` Szczegółowe informacje o przetwarzaniu danych osobowych`}</h3>
    <br />
    <p><strong parentName="p">{`SZCZEGÓŁOWE INFORMACJE
DLA OSÓB, KTÓRE KONTAKTUJĄ SIĘ Z ADMINISTRATOREM `}</strong></p>
    <p><strong parentName="p">{`W jakim celu dane osobowe będą przetwarzane? `}</strong></p>
    <p>{`Dane osobowe podane w zapytaniu będą przez nas przetwarzane w celu udzielenia odpowiedzi na Państwa zapytanie.`}</p>
    <p><strong parentName="p">{`Jaka jest podstawa prawna do przetwarzania danych osobowych?`}</strong></p>
    <p>{`Podstawą prawną do przetwarzania danych osobowych jest Państwa zgoda wyrażona przy zainicjowaniu kontaktu – art. 6 ust. 1 lit a) Rozporządzenia o ochronie danych osobowych RODO. Dane osobowe mogą być przetwarzane również po zakończeniu kontaktu z Państwem na potrzeby wykazania przebiegu kontaktu w przyszłości. To przetwarzanie opiera się na podstawie uzasadnionego interesu administratora (art. 6 ust. 1 lit f) Rozporządzenia o ochronie danych osobowych RODO). W każdym momencie mogą Państwo cofnąć zgodę na przetwarzanie danych osobowych. Cofnięcie zgody nie powoduje niezgodności z prawem przetwarzania dokonanego przed cofnięciem zgody.`}</p>
    <p><strong parentName="p">{`Jakim odbiorcom dane osobowe mogą być przekazywane?`}</strong></p>
    <p>{`Państwa dane mogą być przekazywane do następujących odbiorców lub kategorii odbiorców danych osobowych tj. dostawcom i serwisantom usług IT, dostawcom serwerów i usług poczty elektronicznej.`}</p>
    <p><strong parentName="p">{`Jak długo dane osobowe będą przechowywane?`}</strong></p>
    <p>{`Dane będą przechowywane do czasu udzielenia odpowiedzi na przesłane zapytanie oraz na czas prowadzenia korespondencji w sprawie zgłoszonego zapytania, ochrony przed roszczeniami czy ustalenia przebiegu korespondencji. Po zakończeniu kontaktu dane mogą być zachowane w celu wykazania kontaktu w przyszłości.`}</p>
    <p><strong parentName="p">{`Czy muszą Państwo podać moje dane? `}</strong></p>
    <p>{`Podanie danych osobowych jest dobrowolne, ale niezbędne do udzielenia odpowiedzi na Państwa zapytanie.`}</p>
    <p><strong parentName="p">{`SZCZEGÓŁOWE INFORMACJE `}</strong></p>
    <p><strong parentName="p">{`DLA OSÓB PROSZĄCYCH O PRZEDSTAWIENIE OFERTY`}</strong></p>
    <p><strong parentName="p">{`W jakim celu dane osobowe będą przetwarzane? `}</strong></p>
    <p>{`Dane osobowe będą przetwarzane w celu przedstawienia oferty i dążenia do zawarcia umowy przez Administratora.`}</p>
    <p><strong parentName="p">{`Jaka jest podstawa prawna do przetwarzania danych osobowych?`}</strong></p>
    <p>{`Podstawą prawną przetwarzania danych osobowych jest żądanie zawarcia umowy przez osobę, której dane dotyczą (art. 6 ust. 1 b) RODO).`}</p>
    <p><strong parentName="p">{`Jakim odbiorcom dane osobowe mogą być przekazywane?`}</strong></p>
    <p>{`Państwa dane mogą być przekazywane do następujących odbiorców lub kategorii odbiorców danych osobowych tj. operatorom pocztowym, firmom kurierskim, firmom księgowym, kancelariom prawnym, dostawcom i serwisantom usług IT, dostawcom serwerów i usług poczty elektronicznej.`}</p>
    <p><strong parentName="p">{`Jak długo dane osobowe będą przechowywane?`}</strong></p>
    <p>{`Dane osobowe będą przechowywane przez okres niezbędny do realizacji celu, dla którego zostały zebrane, jednak nie zostaną usunięte przed okresem przedawnienia roszczeń wynikających z przedstawienia oferty.`}</p>
    <p><strong parentName="p">{`Czy muszą Państwo podać moje dane? `}</strong></p>
    <p>{`Podanie danych osobowych jest dobrowolne, ale bez nich nie moglibyśmy przedstawić Państwu oferty usług Administratora.`}</p>
    <p><strong parentName="p">{`SZCZEGÓŁOWE INFORMACJE DLA OSÓB ZAWIERAJĄCYCH UMOWĘ Z ADMINISTRATOREM`}</strong></p>
    <p><strong parentName="p">{`W jakim celu dane osobowe będą przetwarzane? `}</strong></p>
    <p>{`Dane osobowe będą przetwarzane w celu realizacji umowy, w tym dochodzenie należności wynikających z umowy oraz obrony przed roszczeniami.`}</p>
    <p><strong parentName="p">{`Jaka jest podstawa prawna do przetwarzania danych osobowych?`}</strong></p>
    <p>{`Podstawą prawną przetwarzania danych osobowych jest umowa zawarta z Administratorem (art. 6 ust. 1 b) Rozporządzenia o ochronie danych RODO).`}</p>
    <p>{`Dodatkowo dane osobowe mogą być przetwarzane w związku z obowiązkami prawnymi ciążącymi na Administratorze tj. obowiązkami podatkowymi (art. 6 ust. 1 c) Rozporządzenia o ochronie danych RODO).`}</p>
    <p><strong parentName="p">{`Jakim odbiorcom dane osobowe mogą być przekazywane?`}</strong></p>
    <p>{`Państwa dane mogą być przekazywane do następujących odbiorców lub kategorii odbiorców danych osobowych tj. operatorom pocztowym, firmom kurierskim, firmom księgowym, kancelariom prawnym, dostawcom i serwisantom usług IT, dostawcom serwerów i usług poczty elektronicznej.`}</p>
    <p><strong parentName="p">{`Jak długo dane osobowe będą przechowywane?`}</strong></p>
    <p>{`Dane osobowe będą przechowywane przez okres niezbędny do realizacji celu, dla którego zostały zebrane, jednak nie zostaną usunięte przed okresem przedawnienia roszczeń z umowy lub końcem realizacji obowiązków podatkowych.`}</p>
    <p><strong parentName="p">{`Czy muszę podać moje dane?`}</strong></p>
    <p>{`Podanie danych osobowych jest dobrowolne, ale jest warunkiem zawarcia i realizacji umowy.`}</p>
    <p>{`Bez nich nie moglibyśmy zawrzeć oraz realizować umowy.`}</p>
    <br />
    <h3>{`CZĘŚĆ III `}<br />{` Pliki Cookies`}</h3>
    <br />
    <p>{`Strona Internetowa wykorzystuje pliki cookies („`}<strong parentName="p">{`ciasteczka`}</strong>{`”). Są to dane informatyczne (m.in. pliki tekstowe). Są one przechowywane w urządzeniach końcowych w celu usprawnienia korzystania ze stron internetowych.`}</p>
    <p>{`Ciasteczka mogą być używane w celu:`}</p>
    <ol>
      <li parentName="ol">{`dostosowania treści Strony internetowej Państwa preferencji;`}</li>
      <li parentName="ol">{`optymalizacji korzystania ze Strony Internetowej;`}</li>
      <li parentName="ol">{`tworzenia dostosowanych reklam;`}</li>
      <li parentName="ol">{`tworzenia anonimowych statystyk, które umożliwiają zrozumienie sposobu korzystania użytkownika ze Strony Internetowej.`}</li>
    </ol>
    <p>{`Właściciel Strony internetowej nie identyfikuje tożsamość użytkownika w oparciu o dane zgromadzone w ciasteczkach.`}</p>
    <p>{`Stosowane są następujące rodzaje plików:`}</p>
    <ol>
      <li parentName="ol">{`sesyjne;`}</li>
      <li parentName="ol">{`stałe.`}</li>
    </ol>
    <p>{`Pliki sesyjne przechowywane są tymczasowo tj. pozostają one w pamięci urządzenia do czasu wylogowania, wyłączenia strony internetowej czy przeglądarki.`}</p>
    <p>{`Natomiast pliki stałe pozostają w pamięci urządzenia użytkownika w czasie określonym w parametrach pliku cookies albo do momentu usunięcia pliku przez użytkownika.`}</p>
    <p>{`Przeglądarki internetowe w sposób domyślny dopuszczają możliwość umieszczenia plików cookies na urządzeniach końcowych. Ustawienia mogą zostać zmienione przez użytkownika. Jeżeli nie zmienią Państwo ustawień plików cookies, uznaje się, że wyrazili Państwo zgodę na korzystanie z plików cookies.`}</p>
    <p>{`Strona Internetowa korzysta z następujących plików cookies:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Google Analitics`}</strong>{` – jest to produkt dostarczony przez Google LLC. Przetwarzanie danych opiera się na uzasadnionym interesie polegającym na tworzeniu statystyk i analizie mającej na celu optymalizację strony internetowej. Zebrane w ten sposób dane nie umożliwiają identyfikacji konkretnej osoby. Polityka prywatności Google Analitics znajduje się pod adresem: `}<a parentName="li" {...{
          "href": "https://marketingplatform.google.com/about/analytics/terms/us/"
        }}>{`https://marketingplatform.google.com/about/analytics/terms/us/`}</a></li>
      <li parentName="ol"><strong parentName="li">{`Facebook Pixel `}</strong>{`– jest to narzędzie dostarczone przez Facebook Inc, z siedzibą w Stanach Zjednoczonych. Służy ono do tworzenia spersonalizowanych reklam. Działania w tym zakresie oparte są o uzasadniony interes administratora danych osobowych, który polega na promocji produktów i usług Administratora. Zebrane dane nie umożliwiają identyfikacji konkretnej osoby. Polityka prywatności znajduje się pod adresem: `}<a parentName="li" {...{
          "href": "https://www.facebook.com/privacy/explanation"
        }}>{`https://www.facebook.com/privacy/explanation`}</a></li>
    </ol>
    <p>{`Szczegółowe informacje o możliwości i sposobach korzystania z ciasteczek dostępne są w ustawieniach przeglądarki internetowej.`}</p>
    <p>{`Proszę mieć na uwadze, że ograniczenie stosowania ciasteczek może wpłynąć na niektóre funkcje dostępne na Stronie internetowej.`}</p>
    <p>{`Na Stronie internetowej znajdują się wtyczki do następujących serwisów społecznościowych:`}</p>
    <ol>
      <li parentName="ol">{`Youtube.`}</li>
    </ol>
    <p>{`Jeżeli skorzystają Państwo z wtyczki w celu wejścia na portal społecznościowy ze Strony internetowej, to ten portal może uzyskać informację, że odwiedzili Państwo Stronę internetową.`}</p>
    <p>{`Administrator nie otrzymuje informacji o gromadzonych danych osobowych.`}</p>
    <p>{`Jeżeli chcą Państwo dowiedzieć się więcej o przetwarzaniu danych osobowych, to polecamy zapoznać się z polityką prywatności dostawców portali społecznościowych.`}</p>
    <p>{`Jeżeli nie chcą Państwo, żeby portale społecznościowe pozyskiwały o Państwu informacje, to proponujemy wylogować się z serwisów społecznościowych. Chociaż nie zawsze gwarantuje to, że dane nie zostaną pozyskane przez portal społecznościowy.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      