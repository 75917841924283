import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Praca w nocy",
  "slug": "praca-w-nocy",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/114421034141063",
  "image": "images/praca-w-nocy.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-05-20",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Noc nam nie straszna!😄 Czasami wiercimy po zmroku - do oporu - aż znajdziemy wodę💦 dla naszych klientów!💪`}</p>
    <p>{`Zapraszamy do współpracy!`}</p>
    <p>{`#AquatexBeskidy`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      