import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Team przyłączeniowy HYDRO",
  "slug": "hydro-podlaczenia-u-klientow",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/180343377548828",
  "image": "images/hydro-podlaczenia-u-klientow.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-09-22",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Nasz team przyłączeniowy HYDRO💧 - zawsze po wierceniu wchodzi do akcji👊 i podłącza studnie do domów naszych klientów.💦`}</p>
    <p>{`✅Prezentujemy złącze szwedzkie, które jest zdecydowanie najlepszym rozwiązaniem jeśli chodzi o przyłączenie wody👌. Oferuje łatwy montaż/demontaż pompy głębinowej w odwiercie. Zawór szwedzki umożliwia "zawieszenie" pompy bezpośrednio w otworze studziennym bez konieczności wyprowadzania rury tłocznej ponad rurę osłonową.`}</p>
    <p>{`Aquatex THE BEST! 💪💪Polecamy się😎! Czekamy na Was!✅`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      