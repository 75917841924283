import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Ekipa HYDRO",
  "slug": "ekipa-hydro",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/122801086636391",
  "image": "images/ekipa-hydro.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-06-18",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`☀️🔥A Wy jak radzicie sobie z upałami?🥵`}</p>
    <p>{`MY postanowiliśmy trochę popływać 😆🏊‍♀️`}</p>
    <p>{`Szybkie chłodzenie i ekipa HYDRO💧 leci do następnego klienta spragnionego czystej wody💪😎`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      