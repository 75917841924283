import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Kojarzycie jegomościa ze zdjęcia?",
  "slug": "strus-pedziwiatr-aquatex",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/323406053242559/",
  "image": "images/strus-pedziwiatr-aquatex.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2022-05-12",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Właśnie tak pędzimy do naszych klientów w usłudze wiercenia ekspresowego🚀‼️😄
Potrzebujesz wodę na już?☎ Dzwonisz a my po 2 dniach wiercimy Twoje własne źródło wody!💦😎`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      