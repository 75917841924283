import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "HeaderBottom",
  "links": [{
    "text": "Strona główna",
    "type": "link",
    "url": "/",
    "specialClass": ""
  }, {
    "text": "O nas",
    "type": "link",
    "url": "/about/",
    "specialClass": ""
  }, {
    "text": "Oferta",
    "type": "link",
    "url": "/offer/",
    "specialClass": ""
  }, {
    "text": "Studnie głębinowe",
    "type": "link",
    "url": "/deep-well/",
    "specialClass": ""
  }, {
    "text": "Odwierty",
    "type": "link",
    "url": "/borehole/",
    "specialClass": ""
  }, {
    "text": "Realizacje",
    "type": "link",
    "url": "/works/",
    "specialClass": ""
  }, {
    "text": "Kontakt",
    "type": "link",
    "url": "/contact/",
    "specialClass": ""
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      