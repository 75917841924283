import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Oddział HYDRO",
  "slug": "oddzial-hydro",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/116986063884560",
  "image": "images/oddzial-hydro.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-05-28",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`🔥Cały czas się rozwijamy i tworzymy nowy oddział HYDRO💦! To nasza ekipa specjalistów 💪 od kompleksowych przyłączeń wody do budynków wraz z nowoczesnymi stacjami uzdatniania wody!`}</p>
    <p>{`#AquatexBeskidy  zadbamy o Twoja wodę! Prośba o udostępnienie posta🙂`}</p>
    <p>{`Zainteresowany? Zadzwoń☎ 601282929`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      