import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "STUDNIA GŁĘBINOWA daje nam możliwość korzystania z darów natury!",
  "slug": "woda-dar-natury",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/328030849446746/",
  "image": "images/woda-dar-natury.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2022-05-19",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`☝️A wiedzieliście że....‼️
W 2022 r. podczas Światowego Dnia Wody, obchodzonego 22 marca, szczególną uwagę zwrócono na wody podziemne. Nowy raport ONZ na temat zasobów wody świata zatytułowano „Groundwater: Making the invisible visible”!💦💧
🚰Wody podziemne „dostarczają połowę całej wody pitnej, ponad 40 proc. wody do nawadniania i jedną trzecią zużycia wody przez sektor przemysłowy na Ziemi”, a „2,5 miliarda ludzi polega wyłącznie na wodach podziemnych w celu zaspokojenia codziennego zapotrzebowania na wodę pitną i inne domowe potrzeby.
To właśnie STUDNIA GŁĘBINOWA daje nam możliwość korzystania z darów natury!🤩 Czekamy na Was☎️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      