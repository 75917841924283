import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Zalecamy jednak wywiercić studnie żeby potem nie było...",
  "slug": "zeby-potem-nie-bylo",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/133906322192534",
  "image": "images/zeby-potem-nie-bylo.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-07-13",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Upałów🥵 ciąg dalszy i trzeba się chłodzić ⛱`}</p>
    <p>{`Dlatego nasi SPECJALSI😎 zalecają jednak wywiercić studnie żeby potem nie było...😅`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      