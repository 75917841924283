import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Inwestycja w studnię głębinową?",
  "slug": "inwestycja-w-studnie-glebinowa",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/121110333472133",
  "image": "images/inwestycja-w-studnie-glebinowa.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-06-11",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`👉Dlaczego warto zainwestować w studnie głębinową?💧`}</p>
    <p>{`Zużycie wody w domach jednorodzinnych (przy 4-osobowej rodzinie) to średnio 400 litrów dziennie! Jeżeli dodamy do tego pielęgnacje ogrodu ta ilość może wzrosnąć dwukrotnie! 😮`}</p>
    <p>{`Studnia głębinowa to znakomita inwestycja, która zwraca się już w pierwszych latach użytkowania👌. Jeśli instalacja jest dobrze wykonana ( a z NAMI macie tą gwarancje!😎)  nie straszne są jej awarie, tak jak to często bywa w przypadku wodociągów.`}</p>
    <p>{`Zapraszamy do współpracy! 💪`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      