import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Jak znajdujemy wodę?",
  "slug": "pan-ryszard",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/159689182849145",
  "image": "images/pan-ryszard.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-06-19",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Jak znajdujemy wodę? 💧`}</p>
    <p>{`Mamy wybitnego specjaliste w tej dziedzinie - 100% skuteczności! 👑`}</p>
    <p>{`Pan Ryszard to niesamowity człowiek 🧙‍♂️ z 40-letnim doświadczeniem‼️💎`}</p>
    <p>{`W ekipie Aquatex Beskidy mamy najlepszych fachowców!💪 Zapraszamy do współpracy! ☎️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      