import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Kontakt",
  "meta": {
    "description": "Kontakt - AQUA-TEX Beskidy",
    "title": "Kontakt",
    "permalink": "/contact/"
  },
  "contact": {
    "title": "Skontaktuj się z nami",
    "subTitle": "Zapraszamy do kontaktu - do każdego podchodzimy indywidualnie. Z klientami umawiamy się w dogodnym dla nich terminie.",
    "pinTitle": "Adres:",
    "pinDesc": "Plażowa 6, 34-350 Węgierska Górka",
    "buttons": [{
      "text": "Węgierska Górka",
      "icon": "assets/icons/pin.svg",
      "url": "/",
      "type": "buttonPrimary"
    }, {
      "text": "601 282 929",
      "icon": "assets/icons/phone.svg",
      "url": "tel:+48601282929",
      "type": "buttonTertiary"
    }, {
      "text": "Napisz do nas",
      "icon": "assets/icons/mail.svg",
      "url": "mailto:biuro@aquatexbeskidy.pl",
      "type": "buttonTertiary"
    }]
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      