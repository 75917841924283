import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Inwestycja w wodę",
  "slug": "inwestycja-w-wode",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/111887567727743",
  "image": "images/inwestycja-w-wode.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-05-12",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Jak tam Wasza woda?😉`}</p>
    <p>{`Decydując się na studnię głębinową, trzeba wiedzieć, że jest to inwestycja〽️ przede wszystkim we własną wodę💧, często jakościowo lepszą od tej oferowanej przez sieć wodociągową.`}</p>
    <p>{`Podczas ich tworzenia, działania naszego zespołu💪 są tak przemyślane, aby cała inwestycja była jak najbardziej opłacalna, przewidywalna oraz świadoma.😎`}</p>
    <p>{`☝️Studnie głębinowe, nazywane również wierconymi lub rurowymi, wykonywane są za pomocą tzw. wiertnic⚙️. Są to specjalne urządzenia, które działają w oparciu o dwa rodzaje rur – osłonowe i filtracyjne.`}</p>
    <p>{`✅Rury osłonowe docierają w okolice warstwy wodonośnej`}</p>
    <p>{`✅Rury filtracyjne wnikają w nią`}</p>
    <p>{`📣Dzięki temu, że pogłębianie studni wierconej jest możliwe na głębokość ponad 10 m, wydobywana z niej woda jest pozbawiona zanieczyszczeń pochodzących np. z nawożenia gruntów lub nieszczelności infrastruktury kanalizacyjnej. 👌`}</p>
    <p>{`📣Najważniejsze jest to, że cały system daje pewność nieprzerwanego zaopatrzenia w wodę oraz działa niezależnie od wodociągów.`}</p>
    <p>{`Zapraszamy do współpracy!👊💪`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      