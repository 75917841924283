import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Czy w studnie głębinową warto zainwestować?",
  "slug": "inwestowanie-w-studnie",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/130073725909127",
  "image": "images/inwestowanie-w-studnie.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-07-07",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`📣Czy w studnie głębinową warto zainwestować?🤔 Czy to się opłaca?🤨`}</p>
    <p>{`Zobaczcie sami👇`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      