import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "A kiedy maszyny zawodzą, pojawia się wsparcie",
  "slug": "pojawia-sie-wsparcie",
  "url": "https://www.facebook.com/aquatexbeskidy/videos/933739783937223/",
  "image": "images/pojawia-sie-wsparcie.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-11-25",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Czasami maszyny zawodzą, dlatego pojawia się wsparcie!🔥🔥 W pracy zawsze dbamy o to aby wiercenie przebiegało bez większych przerw!😎`}</p>
    <p>{`Profesjonalni jak zawsze! 💪 Zapraszamy do współpracy ☎`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      