import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "STUDNIA GŁĘBINOWA to nie tylko ogromne oszczędności w Waszych portfelach",
  "slug": "nie-tylko-ogromne-oszczednosci",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/139213264995173",
  "image": "images/nie-tylko-ogromne-oszczednosci.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-07-21",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`📣STUDNIA GŁĘBINOWA to nie tylko ogromne oszczędności w Waszych portfelach, ale również gwarancja, że nigdy nie zbraknie Wam wody‼️💦😎`}</p>
    <p>{`✅Rezerwy wód podziemnych na terenie Polski wynoszą 79,3% zasobów dostępnych do zagospodarowania. To wielki rezerwuar słodkiej wody.  Zasilane są opadami i filtrowane przez warstwy przepuszczalne.`}</p>
    <p>{`✅Przyjmuje się, że wody znajdujące się w górnej, mierzącej do 2 km warstwie ziemi mogą się odnowić w okresie równym czasowi życia człowieka („Nature Geoscience” PAP).`}</p>
    <p>{`Także pozostaje tylko WIERCIĆ!👊😃 Zapraszamy do współpracy☎️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      