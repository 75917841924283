import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Przed nami fala upałów",
  "slug": "fala-upalow",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/122704209979412",
  "image": "images/przed-nami-fala-upalow.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-06-17",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Przed nami fala upałów🥵 a gminy apelują o oszczędzanie wody do mieszkańców, którzy korzystają z wodociągów. W niektórych gminach za niedostosowanie się, grożą nawet kary.⛔️‼️⚠️`}</p>
    <p>{`👆I tu z pomocą przyjeżdża Aquatex Beskidy. Jeśli nie chcesz się martwić falą upałów i chcesz aby Twój ogród był regularnie podlewany⚙️ WYWIERĆ Z NAMI STUDNIE GŁĘBINOWĄ 💦`}</p>
    <p>{`Upały nam niestraszne! 💪`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      