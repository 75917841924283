import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "MY jak zwykle, specjaliści od zadań i terenów specjalnych",
  "slug": "my-jak-zwykle-specjalisci",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/142588864657613",
  "image": "images/my-jak-zwykle-specjalisci.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-07-26",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`📣MY jak zwykle, specjaliści od zadań i terenów specjalnych😎, musieliśmy trochę pobuszować w lesie 😆 Chłopaki z HYDRO💧 nie boją się nawet najgęstszych chaszczy 💪`}</p>
    <p>{`Z takim sprzętem nie ma dla nas terenu nie do zdobycia 👑`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      