import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Realizacje",
  "meta": {
    "description": "Firma AQUA-TEX Beskidy z siedzibą w Węgierskiej Górce oferuje swoje usługi dla klientów w obrębie Beskidów, Województwa Śląskiego, Małopolskiego.",
    "title": "Realizacje",
    "permalink": "/works/"
  },
  "photosTitle": "Sfotografowane realizacje",
  "videosTitle": "Sfilmowane realizacje",
  "videos": [{
    "title": "Krótkie ujęcie z roboty",
    "date": "2023-05-30",
    "videoId": "uEKxW5fuRgo",
    "videoTitle": "Przedstawiamy krótkie ujęcie z roboty!"
  }, {
    "title": "Wyciąganie rur osłonowych",
    "date": "2023-05-30",
    "videoId": "pJgdCRwraSA",
    "videoTitle": "Przedstawiamy proces wyciągania rur osłonowych!"
  }, {
    "title": "Obsypywanie rury studzienne",
    "date": "2023-05-30",
    "videoId": "QxaNhyJfyi0",
    "videoTitle": "Przedstawiamy jak obsypujemy rurę studzienną!"
  }, {
    "title": "Uszczelnianie warstwy wody powierzchniowej",
    "date": "2023-05-30",
    "videoId": "2H2eCyuSkHg",
    "videoTitle": "Przedstawiamy jak uszczelniamy warstwę wody powierzchniowej!"
  }, {
    "title": "Nie niszczymy działki!",
    "date": "2023-05-30",
    "videoId": "VJFubs93nXI",
    "videoTitle": "Przedstawiamy jak radzimy sobie z podmokłym terenem!"
  }, {
    "title": "Slow motion... czemu nie!",
    "date": "2023-05-30",
    "videoId": "IkhJjgrf7oU",
    "videoTitle": "Przedstawiamy jak bawimy się slow motion :D !"
  }, {
    "title": "Pozdrowienia dla klientów!",
    "date": "2023-05-30",
    "videoId": "v84_feIERug",
    "videoTitle": "Pozdrowienia od zespołu!"
  }, {
    "title": "Zimą też się da!",
    "date": "2022-12-12",
    "videoId": "87XXzJ7urP8",
    "videoTitle": "Zima też może być naszą przyjaciółką..."
  }, {
    "title": "Czasami warto poczekać...",
    "date": "2022-10-22",
    "videoId": "n-XEP6jGGfo",
    "videoTitle": "Jak cenna jest cierpliwość w naszej branży"
  }, {
    "title": "Węgierska Górka",
    "date": "2022-09-12",
    "videoId": "odnFfu8clBQ",
    "videoTitle": "Powracamy w nasze rodzinne tereny!"
  }, {
    "title": "Ujsoły Glinka - domek kempingowy",
    "date": "2021-08-11",
    "videoId": "6fXWiamOFQI",
    "videoTitle": "Przedstawiamy zmagania w Ujsołach!"
  }, {
    "title": "Zapraszamy do współpracy!",
    "date": "2021-07-12",
    "videoId": "9vxHZT8QmkE",
    "videoTitle": "Dlaczego powinniście nas wybrać..."
  }, {
    "title": "30m osłonowania!",
    "date": "2021-06-22",
    "videoId": "bRWoDhRTxo8",
    "videoTitle": "Przedstawiamy jak nasz zespół walczy z ciężkim terenem!"
  }, {
    "title": "Przyszła zima, wody ni ma!",
    "date": "2021-12-09",
    "videoId": "sXoQc7U7RZk",
    "videoTitle": "Przedstawiamy jak nasz zespół walczy zimą!"
  }, {
    "title": "Złączek 3tysiące",
    "date": "2021-11-01",
    "videoId": "Zxr9O_Xdsl4",
    "videoTitle": "Przedstawiamy efekt końcowy podpięcia zestawu umożliwiającego dostarczenie wody do mieszkania."
  }, {
    "title": "Kompresor",
    "date": "2021-11-01",
    "videoId": "GMx86IvEpbM",
    "videoTitle": "Przedstawiamy krótki urywek z pracy kompresora."
  }, {
    "title": "Cięcina Górna - Abrahamów: Fontanna",
    "date": "2021-11-01",
    "videoId": "Yue5hxxMnps",
    "videoTitle": "Przedstawiamy jak nasza ekipa działa na terenie Cięciny - mamy eleganckie źródło wody."
  }, {
    "title": "Cięcina Górna - Abrahamów: Woda",
    "date": "2021-11-01",
    "videoId": "psIQqmb0nzQ",
    "videoTitle": "Przedstawiamy jak nasza ekipa działa na terenie Cięciny - dowierciliśmy się do wody."
  }, {
    "title": "Juszczyna Brzuśnik - Składanie rury studziennej",
    "date": "2021-11-01",
    "videoId": "iYZlgjtmXCY",
    "videoTitle": "Przedstawiamy naszą ekipę podczas pracy - składanie rury studziennej."
  }, {
    "title": "Juszczyna Brzuśnik",
    "date": "2021-11-01",
    "videoId": "genC34qbASM",
    "videoTitle": "Przedstawiamy nasze kolejne zlecenie - Juszczyna Brzuśnik."
  }, {
    "title": "Cięcina Górna - Abrahamów: Kompresor",
    "date": "2021-11-01",
    "videoId": "YsKrvbyTOjE",
    "videoTitle": "Przedstawiamy jak nasza ekipa działa na terenie Cięciny - kompresor możemy umieścić w odległości od miejsca wiercenia nawet do 50m!"
  }, {
    "title": "Cięcina Górna - Abrahamów: Wiercenie",
    "date": "2021-11-01",
    "videoId": "GZY65Wf4KOo",
    "videoTitle": "Przedstawiamy jak nasza ekipa działa na terenie Cięciny."
  }, {
    "title": "Cięcina Górna - Abrahamów: Wjazd",
    "date": "2021-11-01",
    "videoId": "UgWXz7P7VOA",
    "videoTitle": "Przedstawiamy jak nasza ekipa radzi sobie w Cięcinie przy podjeździe na teren pracy."
  }, {
    "title": "Cięcina Górna - Abrahamów",
    "date": "2021-11-01",
    "videoId": "PmKcBJTTiVM",
    "videoTitle": "Przedstawiamy nasze kolejne zlecenie - Cięcina Górna."
  }, {
    "title": "Rycerka Górna - Ekipa HYDRO",
    "date": "2021-07-19",
    "videoId": "QWnFQCEYxXc",
    "videoTitle": "Przedstawiamy jak nasza ekipa HYDRO w pięknych okolicznościach przyrody wykonuje instalacje przyłączeniową wody."
  }, {
    "title": "Zdradzamy tajniki szukania źródła wody",
    "date": "2021-06-29",
    "videoId": "-TDHmTMxt1s",
    "videoTitle": "Przedstawiamy jak nasz doświadczony specjalista Pan Ryszard odkrywa źródło wody."
  }, {
    "title": "Krótka relacja z wiercenia",
    "date": "2021-06-29",
    "videoId": "dyV3ls0KHuo",
    "videoTitle": "Przedstawiamy krótką relację z wiercenia w pięknych okolicznościach przyrody."
  }, {
    "title": "Wjazd w ciężkim terenie - Górna Cięcina",
    "date": "2021-06-29",
    "videoId": "Bj8R4szog-s",
    "videoTitle": "Przedstawiamy jak radzimy sobie w ciężkim terenie, tym razem Górna Cięcina."
  }, {
    "title": "Nowy nabytek - AQUA-TEX Igor",
    "date": "2021-06-29",
    "videoId": "qQ4QGG2747k",
    "videoTitle": "Przedstawiamy nasz nowy szybki jak błyskawica nabytek - AQUA-TEX Igor!"
  }, {
    "title": "Wykorzystanie koparki",
    "date": "2021-06-29",
    "videoId": "BrBMWwQkvgo",
    "videoTitle": "Przedstawiamy jak wspomagamy się koparką przy pracach."
  }, {
    "title": "Ustawienie kompresora",
    "date": "2021-06-29",
    "videoId": "8uSd6vfmYZY",
    "videoTitle": "Przedstawiamy jak szukamy odpowiedniego miejsca dla kompresora."
  }, {
    "title": "Wjazd w ciężkim terenie - Zwardoń (cz.2)",
    "date": "2021-06-29",
    "videoId": "teEx6p6cyes",
    "videoTitle": "Przedstawiamy jak radzimy sobie w ciężkim terenie, tym razem Zwardoń."
  }, {
    "title": "Wjazd w ciężkim terenie - Zwardoń (cz.1)",
    "date": "2021-06-29",
    "videoId": "HcE89WBMX-I",
    "videoTitle": "Przedstawiamy jak radzimy sobie w ciężkim terenie, tym razem Zwardoń."
  }, {
    "title": "Relacja z powrotu",
    "date": "2021-05-30",
    "videoId": "S6uuDGGwgiA",
    "videoTitle": "Przedstawiamy krótką relację z powrotu od klienta."
  }, {
    "title": "Wyjazd w ciężkich warunkach",
    "date": "2021-05-30",
    "videoId": "Xom2hP67cI0",
    "videoTitle": "Przedstawiamy jak radzimy sobie przy wyjeździe z ciężkiego terenu."
  }, {
    "title": "Wymiana koronki wiertła",
    "date": "2021-05-30",
    "videoId": "j21n-TMTuY0",
    "videoTitle": "Przedstawiamy proces wymiany koronki do wiercenia udarowego."
  }, {
    "title": "Odkręcanie wiertła",
    "date": "2021-05-30",
    "videoId": "r-tdEC8MRB8",
    "videoTitle": "Przedstawiamy proces odkręcania wiertła udarowego."
  }, {
    "title": "Rozpoczynamy wymianę wiertła udarowego",
    "date": "2021-05-30",
    "videoId": "tWVPKyPF8QM",
    "videoTitle": "Przedstawiamy wstępny proces podejścia do wymiany wiertła udarowego."
  }, {
    "title": "Górskie przeciwności",
    "date": "2021-04-14",
    "videoId": "_9KEOJhYt3c",
    "videoTitle": "Przedstawiamy jak radzimy sobie z przeciwnościami w ciężkich górskich warunkach."
  }, {
    "title": "Ciężkie warunki",
    "date": "2021-04-14",
    "videoId": "dBBnAogdH2Y",
    "videoTitle": "Przedstawiamy jak radzimy sobie w ciężkich górskich warunkach."
  }, {
    "title": "Wiercenie wstępne po założeniu rury osłonowej",
    "date": "2021-04-14",
    "videoId": "IE26or92C40",
    "videoTitle": "Przedstawiamy proces wiercenia wstępnego po założeniu 9 metrów rury osłonowej. Na filmie prezentujemy również odprowadzanie urobku na działkę."
  }, {
    "title": "Montaż osłonowej rury",
    "date": "2021-04-14",
    "videoId": "24lJnHcr7oU",
    "videoTitle": "Przedstawiamy montaż osłonowej rury, która zabezpiecza rurę studzienną przed zasypywaniem się."
  }, {
    "title": "Wjazd na działkę",
    "date": "2021-04-14",
    "videoId": "-anb3b4UfeA",
    "videoTitle": "Przedstawiamy moment wjazdu na działkę wraz z prezentacją braku wyrządzonych szkód."
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      