import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Bardzo dobra jakość wody na Śląsku!",
  "slug": "bardzo-dobra-jakosc-wody-slask",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/166756398907526",
  "image": "images/bardzo-dobra-jakosc-wody-slask.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-09-01",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`📣Jak wynika z badań Monitoringu Jakości Wód Podziemnych💦 w 2020 roku - na większości terenu województwa Śląskiego woda klasyfikuje się między I a III klasą jakości czyli od bardzo dobrej jakości wody po zadowalającą😍`}</p>
    <p>{`A to oznacza, że wywiercenie studni praktycznie w 95% gwarantuje dobrą wodę w Waszych domach💪 redukując koszty do minimum!😎`}</p>
    <p>{`Zainwestuj w studnie głębinową z AQUA-TEX👌! Zapraszamy do kontaktu☎`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      