import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Przyłącze wody do domu",
  "slug": "przylacze-wody-do-domu",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/118160187100481",
  "image": "images/przylacze-wody-do-domu.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-06-01",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Długi weekend przed nami to krótka historyjka z pracowitego dnia 😁👉`}</p>
    <p>{`⭐️Klient: Dzień Dobry chciałbym żebyście mi Panowie wykonali przyłącze wody do domu i zamontowali stacje uzdatniającą. Tylko jest pewien problem - mieszkam w bardzo trudno dostępnym terenie i wątpliwe że dacie radę dojechać...`}</p>
    <p>{`🔥Ekipa  HYDRO: Już do Pana jedziemy 😎`}</p>
    <p>{`Nasz sprzęt zawsze da rade!💪`}</p>
    <p>{`Zainteresowany krystalicznie czystą wodą? Zadzwoń ☎601282929☎`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      