import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "O nas",
  "meta": {
    "description": "AQUA-TEX Beskidy z Węgierskiej Górki zajmuje się studniami głębinowymi oraz odwiertami pod pompy ciepła w obrębie Beskiów oraz dla Śląska, Małopolski.",
    "title": "O nas",
    "permalink": "/about/"
  },
  "about": {
    "mainTitle": "Co warto o nas wiedzieć?",
    "contentBlocks": [{
      "title": "company",
      "contentList": [{
        "desc": "Jesteśmy firmą, która działa na terenie województwa śląskiego, a szczególnie w obrębie Beskidów."
      }, {
        "desc": "Posiadamy ponad <strong>10 letnie</strong> doświadczenie w naszym ciężkim, górskim terenie!"
      }, {
        "desc": "Zajmujemy się przede wszystkim: <ul><li>wykonywaniem studni głębinowych</li><li>odwiertami pod pompy ciepła</li></ul>"
      }, {
        "desc": "Wszystko to przy użyciu nowoczesnego i sprawdzonego sprzętu."
      }]
    }, {
      "title": "team",
      "contentList": [{
        "desc": "Nasz zespół składa się z wykwalifikowanych pracowników, posiadamy wszelkie niezbędne uprawnienia."
      }, {
        "desc": "Uzupełniamy regularnie swoją wiedzę w zakresie szybko zmieniajacej się technologii dotyczącej studni głębinowych oraz odwiertów pod pompy ciepła."
      }, {
        "desc": "Każda realizacja którą wykonujemy, poprzedzona jest dogłębną i szczegółową analizą oczekiwań klienta."
      }]
    }],
    "viewOfWorkImgList": [{
      "image": "images/about/about-photo-1.jpg"
    }, {
      "image": "images/about/about-photo-2.jpg"
    }, {
      "image": "images/about/about-photo-3.jpg"
    }],
    "subTitle": "Dlaczego my?",
    "whyUsList": [{
      "title": "Znajomość terenu",
      "desc": "Wyróżnia nas bardzo dobra orientacja oraz znajomość Beskidów. Odnajdujemy się również na innych terenach."
    }, {
      "title": "Indywidualne podejście",
      "desc": "Każdy klient posiada inne potrzeby oraz koncepcje, dlatego jesteśmy elastyczni i dostosowujemy się do nich najlepiej jak potrafimy."
    }, {
      "title": "Szybkie działanie",
      "desc": "Wiemy, że dla każdego klienta najważniejszy jest czas. Działania rozpoczynamy już od pierwszego kontaktu z nami."
    }, {
      "title": "Nowoczesny sprzęt",
      "desc": "Swoje działania opieramy o nowoczesny sprzęt, dzięki czemu możemy wykonywać swoją pracę w każdych warunkach i w każdym terenie."
    }, {
      "title": "Bez zniszczeń",
      "desc": "Prace prowadzimy bez zniszczeń na działce. Jedyna ingerencja w teren odbywa się w miejscu odwiertu."
    }]
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      