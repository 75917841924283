import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Nie zwalniamy tempa i wyrabiamy 300% normy - tak to MY Aquatex PRZODOWNICY PRACY",
  "slug": "rzeczy-niemozliwe-od-reki",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/124146886501811",
  "image": "images/cuda-od-razu.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-06-22",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`📣Nie zwalniamy tempa i wyrabiamy 300% normy - tak to MY Aquatex PRZODOWNICY PRACY!💪`}</p>
    <p>{`Jeśli nie masz czasu na czekanie jak w ogonku za PRL-u i potrzebujesz wody na już... JESTEŚMY‼️`}</p>
    <p>{`👑Wprowadzamy usługę WIERCENIE NATYCHMIASTOWE - czas realizacji maks do 2 dni roboczych (wiercimy również nocą!🔥).`}</p>
    <p>{`Czekamy na Ciebie! ☎️ 👉Prośba o udostepnienie👈￼`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      