import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Odwierty",
  "meta": {
    "description": "Montaż pompy, konserwacja pompy ciepła, odwierty geodezyjne.",
    "title": "Odwierty",
    "permalink": "/borehole/"
  },
  "borehole": {
    "mainTitle": "Odwierty pod pompy ciepła",
    "mainContent": "Ostatnimi czasy pompy ciepła stały się jednym z najkorzystniejszych i najbardziej opłacalnych rozwiązań grzewczych. Znajdują one zastosowanie na każdym terenie geograficznym naszego kraju. Pompy, które pracują z dolnym źródłem, zapewniającym względnie stałą temperaturę przez cały rok, wpływają na znaczne obniżenie się kosztów eksploatacji. <br /> Energia pobierana jest za pomocą kolektora pionowego (sondy) z ziemi, skał oraz wody. Wykonujemy możliwie jak najgłębszy odwiert, przy relatywnie jak najniższej cenie, stosując różnorodne metody wiercenia przy odpowiednich do zaistniałej geologii warunkach. Najczęściej zakładamy kolektory pionowe mierzące ok. 100 m. Przy takiej wysokości kolektora pionowego, temperatura pozwala na uzyskanie najlepszej sprawności pompy. Działający w taki sposób system ogrzewa zimą, natomiast latem sprawdza się jako klimatyzacja.",
    "equipmentImgList": [{
      "image": "images/borehole/borehole-photo-1.jpg"
    }, {
      "image": "images/borehole/borehole-photo-2.jpg"
    }, {
      "image": "images/borehole/borehole-photo-3.jpg"
    }, {
      "image": "images/borehole/borehole-photo-4.jpg"
    }],
    "subContent": "Użytkowanie pompy ciepła gwarantuje nie tylko kilkakrotnie niższe koszty zużycia energii, ale również niezawodność i niezwykle długą żywotność systemu grzewczego. System ten, w przeciwieństwie do pieców tradycyjnych, jest w pełni zautomatyzowany i nie wymaga dodatkowej obsługi, a temperaturą w domu można sterować przy użyciu smartfona. Pompy ciepła nie generują niepotrzebnych substancji zanieczyszczających powietrze, dzięki czemu są bezpieczne dla środowiska. Jest to idealne rozwiązanie dla osób, które chcą uniknąć bardzo wysokich opłat za ogrzewnie domu zimą oraz ochładzanie go latem. <br /> Jeżeli decyduja się Państwo na montaż pompy ciepła, najlepiej pomyśleć o tym już na etapie projektowania domu, aby uniknąć konieczności przyłącza do gazu lub budowy komina."
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      