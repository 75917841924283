import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Czasami na robocie nie jest łatwo",
  "slug": "czasami-na-robocie-nie-jest-latwo",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/125993206317179",
  "image": "images/czasami-na-robocie-nie-jest-latwo.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-06-30",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Czasami na robocie nie jest łatwo!😫`}</p>
    <p>{`Praca zespołowa to coś co bardzo sobie cenimy‼️👌 Nasza ekipa to zgrany zespół, który poradzi sobie z każdym problemem 💪👊`}</p>
    <p>{`👑🔥Aquatex THE BEST!👑🔥`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      