import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Starszy brat naszego unimoga",
  "slug": "starszy-brat",
  "url": "https://www.facebook.com/permalink.php?story_fbid=2702130316744060&id=100008413545658",
  "image": "images/starszy-brat.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-06-12",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`starszy brat naszego unimoga ￼rocznik 69 podobno`}</p>
    <p>{`jeszcze gigdy się nie zepsuł 🚀🚀🚀👍👍👍💦💦💦￼`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      