import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Korozja rury?!📣",
  "slug": "rura-rdzewieje",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/342932061289958/",
  "image": "images/rura-rdzewieje.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2022-06-22",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`UWAGA‼️ BARDZO WAŻNA INFORMACJA‼️
☝️Zauważyliśmy, że niektóre firmy nadal wiercą studnie głębinowe starą metodą i zostawiają żelazne rury osłonowe wokół rury studziennej. Niestety niewyjęte rury osłonowe po niedługim czasie eksploatacji RDZEWIEJĄ i ZANIECZYSZCZAJĄ WODĘ!
Aquatex Beskidy💧 zawsze wierci studnie nowoczesną metodą nie zostawiając metalowej rury osłonowej! Na życzenie klienta stosujemy wytrzymałe rury plastikowe!💪
Pamiętajcie o tym przy wyborze odpowiedniej firmy😎Prośba o udostępnienie 🙂
Czekamy na Was☎`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      