import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Wodogrzmoty!",
  "slug": "wodogrzmoty",
  "url": "https://www.facebook.com/aquatexbeskidy/videos/626254455054241/",
  "image": "images/wodogrzmoty.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-10-11",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`💦Zobaczcie te wodogrzmoty💦‼️🤩Kolejna znakomita studnia wywiercona!👌`}</p>
    <p>{`Tak - było zabawy z odprowadzeniem tej wody 😄`}</p>
    <p>{`AQUA-TEX THE BEST💪`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      