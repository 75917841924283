import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Czasami teren daje nam w kość i wiercenie idzie ciężko😩",
  "slug": "twardy-teren-daje-w-kosc",
  "url": "https://www.facebook.com/aquatexbeskidy/videos/529596755534464/",
  "image": "images/twardy-teren-daje-w-kosc.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2022-06-17",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Ale ekipa  #aquatexbeskidy nigdy się nie poddaje i walczymy do końca💪‼️ Tym razem również się udało!😎 💦💦💦💦`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      