import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Oferta",
  "meta": {
    "description": "Oferujemy podłączenia instalacji wodnych, szukanie/uzdatnianie wody,czyszczenie studni, montaż pompy, konserwacja pompy ciepła, odwierty geodezyjne.",
    "title": "Oferta",
    "permalink": "/offer/"
  },
  "offer": {
    "mainTitle": "Jakie usługi oferujemy?",
    "mainContent": "Zajmujemy się przede wszystkim wierceniem studni głębinowych jak i odwiertami pod pompy ciepła, co wymaga obszernej i kompleksowej wiedzy oraz doświadczenia. Zanim rozpoczniemy każde zlecenie, sprawdzamy czy na danej działce znajduje się woda głębinowa. Dysponujemy samochodem z kiprem. Na zlecenie klienta wywozimy urobek. Wiertnica posiada unikalny system rurowy, który odprowadza urobek w dogodne miejsce lub bezpośrednio na samochód, co zapobiega zabrudzeniom budynku oraz terenu wokół oraz odróżnia nas od konkurencji. Nasz zespół składa się z zaprawionych w boju fachowców, dzięki którym efekt końcowy pracy zawsze jest zadawalający zarówno dla Państwa jak i dla nas.",
    "subContent": "Jesteśmy otwarci i elastyczni, dlatego wycena jest dostosowywana indywidualnie do potrzeb klienta.",
    "bonusInfo": "Nasi specjaliści wychodząc naprzeciw oczekiwaniom klientów, jako pierwsi wprowadzili nową usługę:",
    "bonusInfoStrong": "Uzdatniania i Dezynfekcji studni w CENIE ODWIERTU!",
    "quickOfferTitle": "Jak działamy krok po kroku?",
    "quickOfferList": [{
      "title": "Kontakt",
      "icon": "assets/icons/phone-me.svg",
      "desc": "Wygodny i szybki kontakt <strong>telefoniczny</strong> lub <strong>mailowy</strong>. Jeśli nie odbieramy, to znaczy, że wiercimy :)"
    }, {
      "title": "Wizja lokalna",
      "icon": "assets/icons/vision.svg",
      "desc": "Wytyczamy studnie i szukamy wody, na miejscu u klienta trzema sposobami: metodą electro oporową, za pomocą radioscezji i na mapach geologicznych. <strong>Przy wykonaniu odwiertu ta usługa jest bezpłatna</strong>."
    }, {
      "title": "Odwiert",
      "icon": "assets/icons/drill-into.svg",
      "desc": "Wykonanie odwiertu, czyli <strong>budowa studni głębinowej</strong> i wszystkie prace z nim związane."
    }, {
      "title": "Podłączenie",
      "icon": "assets/icons/connect-me.svg",
      "desc": "Kompleksowo podłączamy studnie głębinowe do budynków mieszkalnych. Podczas prac dokonujemy wstępnej <strong>dezynfekcji studni w cenie przyłącza</strong>."
    }, {
      "title": "Dozór techniczny",
      "icon": "assets/icons/its-ok.svg",
      "desc": "Zapewniamy dozór techniczny <strong>przez cały czas użytkowania studni</strong>, oraz podłączeń wykonanych przez naszą firmę."
    }],
    "link": [{
      "text": "Zadzwoń",
      "url": "tel:+48601282929",
      "type": "buttonTertiary"
    }],
    "subTitle": "Poniżej przedstawiamy poglądowy cennik:",
    "offerList": [{
      "title": "Szukanie wody",
      "price": "GRATIS!"
    }, {
      "title": "Odwiert próbny",
      "price": "140zł/mb"
    }, {
      "title": "Studnia wraz z systemem filtrującym (125 mm)",
      "price": "220zł/mb"
    }, {
      "title": "Studnia wraz z systemem filtrującym (160 mm)",
      "price": "250zł/mb"
    }, {
      "title": "Odwiert pod pompę ciepła",
      "price": "100zł/mb"
    }, {
      "title": "Przyłącze wody wraz z całym materiałem (pompa, rury, instalacja, przekop, hydrofornia wewnątrz lub na zewnątrz)",
      "price": "3 000 - 5 000zł"
    }, {
      "title": "Usługi mini koparką",
      "price": "70zł/h"
    }, {
      "title": "Usługi samochodem z kiprem",
      "price": "60zł/h"
    }],
    "reviewImgList": [{
      "image": "images/offer/offer-photo-1.jpg"
    }, {
      "image": "images/offer/offer-photo-2.jpg"
    }, {
      "image": "images/offer/offer-photo-3.jpg"
    }]
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      