import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "A co jeśli woda ze studni głębinowej będzie brudna/zanieczyszczona?",
  "slug": "zanieczyszczona-woda",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/341621864754311/",
  "image": "images/zanieczyszczona-woda.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2022-06-09",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`☝️Z takimi pytaniami często się spotykamy a więc odpowiadamy!👇
Pierwszy krok!🩺
Głównym zaleceniem po wywierceniu studni jest oczywiście przebadanie próbki wody w lokalnych laboratoriach, wodociągach lub sanepidzie tak aby się upewnić, że w wodzie nie ma groźnych bakterii lub nadmiaru metali ciężkich. Jeśli zostaną wykryte trzeba będzie zainwestować w profesjonalną stację uzdatniania wody. Jednak z naszego doświadczenia wynika, że większość studni głębinowych nie ma z tym problemu.
Drugi krok!🫗
Jeżeli widzimy że woda jest mętna (zawiera jakieś składniki stałe np. piasek) ale nie ma w niej groźnych bakterii, zalecamy płukanie studni przed przyłączeniem jej do domu. Po przepompowaniu odpowiedniej ilości wody, zalecane jest zamontowanie filtrów polipropylenowych na wejściu wody do instalacji, tak aby wyłapały jeszcze te cząstki stałe, które mogą się po jakimś czasie pojawić w źródle.
Trzeci krok!💧
Pamiętajmy o sprawdzaniu wody i jej stanu regularnie, tak aby zawsze być pewnym, że woda jest zdatna do używania!
Zawsze po wywierceniu studni możecie liczyć na nasze wsparcie i doradztwo! 💪Zapraszamy!☎️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      