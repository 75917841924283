import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Usługi mini koparką",
  "slug": "uslugi-mini-koparka",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/116113627305137",
  "image": "images/uslugi-mini-koparka.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-05-25",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Świadczymy również kompleksowe usługi minikoparką oraz dysponujemy samochodem samowyładowczym do 3,5 ton! 🔥💪Nasza wykwalifikowana ekipa da radę w każdych warunkach! 😎`}</p>
    <p>{`Prośba o udostępnienie posta👍`}</p>
    <p>{`Zapraszamy do kontaktu! Zadzwoń☎️ 601282929`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      