import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Piękny Beskid Żywiecki",
  "slug": "piekny-beskid-zywiecki",
  "url": "https://www.facebook.com/aquatexbeskidy/videos/263849068955480/",
  "image": "images/piekny-beskid-zywiecki.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-10-25",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`🌲🏞Tydzień rozpoczynamy od wiercenia studni w pięknym Beskidzie Żywieckim!🌲🏞 Zobaczcie te widoki i  naszą wiertnice z innej perspektywy 👇🤩`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      