import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Wspomaganie koparką",
  "slug": "wspomaganie-koparka",
  "url": "https://www.facebook.com/aquatexbeskidy/videos/618261139147789/",
  "image": "images/wspomaganie-koparka.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-06-03",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`☝️Czasami bywa, że teren nie pozwala na swobodne wiercenie i musimy sami sobie poradzić przygotowując teren. Wtedy do akcji wkracza nasza 🤩mini koparka i sprawa załatwiona!💪`}</p>
    <p>{`Nie ma terenu którego nie ogarniemy!😎`}</p>
    <p>{`Zapraszamy do współpracy!🤝☎️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      