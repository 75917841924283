import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "A więc wakacje!",
  "slug": "wakacje-aquatex",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/349983823918115/",
  "image": "images/wakacje-aquatex.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2022-06-21",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Zaczęły się wakacje☀️ więc my bierzemy się do pracy jeszcze mocniej!💪 Ktoś jeszcze nie zdecydował się na studnie głębinową☝💦!? To najwyższy czas!😎😁
Zapraszamy do kontaktu☎️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      