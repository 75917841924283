import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Dzisiaj kolejne wymagające wyzwanie przed nami",
  "slug": "dzisiaj-kolejne-wymagajace-wyzwanie-przed-nami",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/126380549611778",
  "image": "images/wiercimy-studnie-bardzo-blisko-domu.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-07-01",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Dzisiaj kolejne wymagające wyzwanie przed nami!🔥`}</p>
    <p>{`Wiercimy studnie bardzo blisko domu, dlatego od razu przystąpiliśmy do zabezpieczenia terenu (w tym świeżo ułożoną kostkę brukową🤩). W odpowiedni sposób wypoziomowaliśmy naszego UNIMOGA🚛 tak aby bezpiecznie zacząć wiercić!💪
Zawsze dbamy o posesje klientów‼😎`}</p>
    <p>{`Aquatex jak zawsze profesjonalni!💪👌`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      