import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Nowy nabytek AQUA-TEX Igor",
  "slug": "aqua-tex-igor",
  "url": "https://www.facebook.com/permalink.php?story_fbid=2698685663755192&id=100008413545658",
  "image": "images/aqua-tex-igor.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-06-13",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Mamy weekend więc trochę z przymrużeniem oka Firma aquaTex zawsze stawia na ekologię`}</p>
    <p>{`Nasz nowy nabytek Podoła wszelkim wyzwaniom i niedogodnością naszych pięknych`}</p>
    <p>{`beskidzkich bezdroży teraz to już naprawdę dojeżdżamy w każde miejsce aby klient był zadowolony😜😜😜￼￼`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      