import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Nasi SPECJALSI Z HYDRO!",
  "slug": "ekipa-hydro-od-rana",
  "url": "https://www.facebook.com/aquatexbeskidy/videos/1095417110983499/",
  "image": "images/ekipa-hydro-od-rana.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-07-19",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Nasi SPECJALSI Z HYDRO💧 od rana zaprawieni w bojach!💪`}</p>
    <p>{`W pięknych okolicznościach przyrody🌄 wykonujemy instalacje przyłączeniową wody😎`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      