import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Stacja uzdatniająca",
  "slug": "stacja-uzdatniajaca",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/120530696863430",
  "image": "images/stacja-uzdatniajaca.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-06-09",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Czysta woda to priorytet w domach naszych klientów☝`}</p>
    <p>{`Nasi specjaliści z HYDRO💪 zamontowali właśnie stacje uzdatniającą, dzięki której nasz klient będzie miał w kranie krystalicznie czystą wodę!💦`}</p>
    <p>{`Jesteś niezadowolony z jakości swojej wody? Zadzwoń do NAS!  ☎️601282929☎️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      