import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Lasy, bagna, moczary...dla naszego UNIMOGA to żadne wyzwanie!",
  "slug": "unimog-niepowstrzymany",
  "url": "https://www.facebook.com/aquatexbeskidy/videos/558834092067526/",
  "image": "images/unimog-niepowstrzymany.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-10-14",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`👊Sprzęt do zadań specjalnych w naszej pracy to podstawa💪. Lasy, bagna, moczary, góry...dla naszego UNIMOGA nie stanowi to problemu!😎`}</p>
    <p>{`TAK - dojedziemy wszędzie!😎 Czekamy na WAS! ☎️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      