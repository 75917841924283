import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Ile płacicie za wodę??",
  "slug": "ile-placisz-za-wode",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/154455786804254",
  "image": "images/ile-placisz-za-wode.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-08-13",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`📣Ile płacicie za wodę??☝️💧💦`}</p>
    <p>{`W woj. Śląskim w roku 2021 ceny wyglądają tak👇`}</p>
    <p>{`✅Średnia cena metra sześciennego wody i ścieków dla całego województwa Śląskiego – 10,44 zł`}</p>
    <p>{`✅Najwyższa cena – Ustroń/Cisownica: 24,02 zł za metr sześcienny`}</p>
    <p>{`✅Najniższa cena – Bielsko-Biała: 9,14 zł za metr sześcienny`}</p>
    <p>{`☝️W domu jednorodzinnym (przy średnio 4 osobowej rodzinie) dobowy pobór wody wliczając jeszcze pielęgnację ogrodu/baseny w sezonie letnim itd....to ok 650 litrów. Koszty roczne przy takim zużyciu i średnich cenach to prawie 2400 złotych!`}</p>
    <p>{`📣Koszt wywiercenia studni głębinowej powinien się zwrócić w przeciągu 2 - 3 lat!`}</p>
    <p>{`🤩Nie czekaj i wywierć z nami swoją studnie głębinową☎️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      