import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Zdecydowanie uwielbiamy wiercić",
  "slug": "teren-domek-campingowy",
  "url": "https://www.facebook.com/aquatexbeskidy/videos/766060604402417/",
  "image": "images/teren-domek-campingowy.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2022-06-13",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Kolejny dzień pracy w terenie!💪 Takie klimaty lubimy, a woda oczywiście odnaleziona!💦💦💦💦
Czekamy na Wasze telefony☎😀`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      