import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Footer",
  "copyright": "Copyright © 2021 Wszystkie prawa zastrzeżone",
  "atbLogo": "assets/icons/atb_logo.svg",
  "nomadsCodes": [{
    "madeBy": "Realizacja strony",
    "name": "Nomads Codes",
    "url": "http://nomads.codes",
    "icon": "assets/icons/nc_sygnet_mono.svg"
  }],
  "quickContact": {
    "title": "Zainteresowany?",
    "desc": "Skontaktuj się z nami! Dostosujemy pod Ciebie najlepsze rozwiązanie.",
    "link": [{
      "text": "601 282 929",
      "url": "tel:+48601282929",
      "type": "buttonSecondary",
      "icon": "assets/icons/phone.svg"
    }]
  },
  "links": [{
    "title": "Produkty",
    "links": [{
      "text": "Studnie głębinowe",
      "url": "/deep-well/"
    }, {
      "text": "Odwierty",
      "url": "/borehole/"
    }],
    "type": "nested"
  }, {
    "title": "Firma",
    "links": [{
      "text": "Kontakt",
      "url": "/contact/"
    }, {
      "text": "O nas",
      "url": "/about/"
    }, {
      "text": "Oferta",
      "url": "/offer/"
    }],
    "type": "nested"
  }, {
    "title": "Pomoc",
    "links": [{
      "text": "Jak działamy",
      "url": "/works/"
    }, {
      "text": "Zapytaj",
      "url": "/#faq-section"
    }, {
      "text": "Nasz Kanał",
      "url": "https://www.youtube.com/channel/UCL3FFwaY5mIyhLgy3uU7YbQ"
    }, {
      "text": "Polityka prywatności",
      "url": "/privacy-policy/"
    }],
    "type": "nested"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      