import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Wiercimy zimą?!",
  "slug": "wiercimy-zima",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/214742184108947",
  "image": "images/wiercimy-zima.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-11-15",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`❄️Czy zimą można wiercić studnie głębinowe?❄️`}</p>
    <p>{`👉To pytanie pada coraz częściej w kontekście nadchodzącej ZIMY, a my naszym przyszłym klientom odpowiadamy - TAK!🤩 Studnie wiercimy na takich głębokościach gdzie mróz nie dociera, a sam odwiert i przyłącze do domu zawsze zabezpieczamy przed przemarzaniem. 💪`}</p>
    <p>{`Nasz sprzęt zawsze da rade, dlatego żadna pora roku nam nie straszna!😎`}</p>
    <p>{`Zapraszamy do kontaktu!☎️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      