import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Nowy, mocny młot do wiercenia aby jeszcze przyspieszyć nasza pracę!",
  "slug": "mlot-puma",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/135521705364329",
  "image": "images/mlot-puma.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-07-15",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`📣Urlopy, urlopiki a MY cały czas przemy do przodu!💪`}</p>
    <p>{`Tym razem przybyła do nas PUMA!😎 😄 Nowy, mocny młot do wiercenia aby jeszcze przyspieszyć nasza pracę!🤩`}</p>
    <p>{`Z PUMĄ wchodzimy na jeszcze wyższy poziom! 👊`}</p>
    <p>{`💦AQUA-TEX ZAWSZE INWESTUJE W ZADOWOLENIE KLIENTÓW!🔥`}</p>
    <p>{`Zapraszamy do współpracy☎️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      