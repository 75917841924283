import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Nasza wiertnica w wiosennych promieniach słońca wygląda okazale!",
  "slug": "nasza-wiertnica-w-promieniach-slonca",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/321538320095999/",
  "image": "images/nasza-wiertnica-w-promieniach-slonca.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2022-05-09",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Pracujemy na najlepszym sprzęcie a jak widać na załączonym obrazku zawsze dbamy o zabezpieczenie posesji klientów!👇
Tak to się nazywa profesjonalizm!💪
Czekamy na Was☎️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      