import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Dzisiaj wyzwanie w górskich terenach!",
  "slug": "gorskie-tereny-aquatex",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/332565468993284/",
  "image": "images/gorskie-tereny-aquatex.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2022-05-26",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`W takich okolicznościach przyrody pracuje się idealnie!😎😄⛱☀️Dzisiaj wyzwanie w górskich terenach ale jak zwykle damy radę z naszym zespołem i sprzętem!💪
Zapraszamy do kontaktu☎️! Dojedziemy wszędzie😁`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      