import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Wiercimy kilkadziesiąt metrów od domu",
  "slug": "wiercimy-kilkadziesiat-metrów-od-domu",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/124949836421516",
  "image": "images/studnia-daleko-od-domu.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-06-25",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`👉Czasami zdarza się, że studnie wiercimy kilkadziesiąt metrów od domu. Dlatego wykorzystujemy naszą niezawodną minikoparkę aby zrobić odpowiedni wykop do położenia rur i przyłączenia wody!`}</p>
    <p>{`Jak zwykle zespół HYDRO💦 spisał się na medal!💪 Kolejny zadowolony klient na naszej liście!🤩`}</p>
    <p>{`Zapraszamy do kontaktu👌☎️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      