import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Upały dają w kość, więc pamiętajcie aby pić regularnie wodę",
  "slug": "upaly-daja-w-kosc",
  "url": "https://www.facebook.com/aquatexbeskidy/posts/123855706530929",
  "image": "images/upaly-daja-w-kosc.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-06-21",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`🥵Upały dają w kość, więc pamiętajcie aby pić regularnie wodę!!💧🥤`}</p>
    <p>{`✅O tym, jak ważne jest zachowanie równowagi między ilością wody traconej a dostarczanej, niech świadczy to, że utrata tylko 1–2 proc.📈 płynów negatywnie wpływa na działanie organizmu. Właśnie dlatego bez jedzenia możemy przetrwać kilka tygodni, a bez picia zaledwie kilka dni. 😮`}</p>
    <p>{`Dlatego mając w domu studnie głębinową na pewno przeżyjesz każde upały😉`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      