import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Zima w pełni ale i wody dużo!",
  "slug": "zima-w-pelni-ale-i-duzo-wody",
  "url": "https://www.facebook.com/aquatexbeskidy/videos/1068993630309782",
  "image": "images/zima-w-pelni-wiert.jpeg",
  "type": "novelties",
  "published": true,
  "date": "2021-12-20",
  "showDate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`💧 Po raz kolejny pokazujemy, że mrozy nie stanowią problemu przy wierceniu studni‼💪
Kolejny zadowolony klient🤩 Studnia głębinowa na prawdę się opłaca!😁 Zapraszamy do kontaktu!☎`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      